.linkStyle {
  text-decoration: none;
}

.button {
  font-size: 15px;
  color: white;
  font-weight: 400;
  font-family: var(--header-font);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  justify-content: left;
  border-radius: 3px;
  padding: 10px 15px 10px 15px;
}


.primary-red {
  background-color: var(--color-red-10);
  color: white;
}

.primary-red:hover {
  opacity: 0.8;
}

.secondary {
  background-color: rgb(243, 243, 243);
  color: var(--color-red-10);
}

.secondary:hover {
  opacity: 0.8;
}

.transparent {
  background-color: transparent;
  transition: background-color 0.1s ease-in-out;
  color: var(--color-red);
}

.transparent:hover {
  background-color: rgb(243, 243, 243)
}

.link {
  color: var(--color-red-10);
  border: none;
}

.link:hover {
  text-decoration: underline;
  padding: 0;
  text-underline-offset: 5px;
}

.outline-red {
  background-color: transparent;
  color: var(--color-red-10);
  transition: background-color 0.1s ease-in-out;
  border: 1px solid var(--color-red-10);
}

.outline-red:hover {
  background-color: rgb(250, 250, 250)
}


.outline-white {
  background-color: transparent;
  color: var(--color-red);
  transition: background-color 0.1s ease-in-out, backdrop-filter 100ms ease-in-out;
  border: 1px solid white;
  color: white;
}

.outline-white:hover {
  background-color: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(2px);
}