.outterContainer {
  background-color: var(--color-grey-very-light);
  padding: 60px 0;
  margin-top: 120px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.award {
  position: relative;
  width: 200px;
  height: 200px;

  @media screen and (min-width: 768px) {
    width: 100px;
    height: 100px
  }
}

.feefoLogo {
  position: relative;
  width: 98px;
  height: 24px;
}

.title {
  display: grid;
  grid-template-rows: auto auto; /* Two rows */
  grid-template-columns: repeat(4, auto); /* 4 columns */
  gap: 10px;
  line-height: 4em;
  text-transform: initial;
  font-family: var(--font-family-body);
  color: var(--color-grey-deep);
  font-size: 20px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* First element spans all 4 columns */
.title > *:first-child {
  grid-row: 1;
  grid-column: 1 / -1; /* Spans from first to last column */
}

/* All other elements sit on row 2, one per column */
.title > *:not(:first-child) {
  grid-row: 2;
}

@media screen and (min-width: 768px) {
  .title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
  }
}

.stars {
  color: var(--color-star);

  &>svg {
    margin-right: 0;
  }
}

.rating {
  font-size: 1.2em;
}

.foot {
  font-size: 14px;
  text-align: center;

  &>strong {
    font-weight: 600;
    color: var(--color-grey-deep);
  }

  &>button {
    margin-left: 10px;
    text-decoration: underline;
  }

  &>a {
    margin-left: 10px;
  }
}

.reviews {
  display: none;
  flex-direction: row;
  border-top: 1px solid var(--color-grey);
  margin-top: 30px;
  padding-top: 30px;
  gap: 30px;

  @media screen and (min-width: 768px) {
    display: flex;
  }
}

.review {
  flex-grow: 1;
  width: 25%;
}

.reviewTitle {
  font-size: 14px;
  font-weight: 800;
}

.reviewText {
  font-size: 14px;
  margin-top: 5px;
}

.reviewDetails {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 12px;
  margin-top: 5px;
  color: var(--color-grey-dark);
}

.customerName {

}

.date {

}
